import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import type { LinksFunction } from "@remix-run/node";
import { WalletProvider } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useCallback } from "react";

import tailwindHref from "~/tailwind.css?url";
import baseHref from "~/base.css?url";
import { Toaster } from "@/components/ui/toaster";
import { useToast } from "@/components/ui/use-toast";

import TopNav from "./components/ui/topnav";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: tailwindHref },
  { rel: "stylesheet", href: baseHref },
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  { rel: "preconnect", href: "https://fonts.gstatic.com" },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Quicksand:wght@400;500;700&display=swap",
  },
];

export async function loader() {
  return json({
    ENV: {
      SOCIALSWAP_API_ROOT_V1: process.env.SOCIALSWAP_API_ROOT_V1,
    },
  });
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useLoaderData<typeof loader>();
  const { toast } = useToast();
  const onError = useCallback(() => {
    toast({
      title: "Wallet Error",
      description: "Unable to connect to the wallet",
      className: "text-white",
    });
  }, []);

  return (
    <html lang="en" className="scrollbar-thumb-sky-900 scrollbar-track-sky-800">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="body">
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        <WalletProvider onError={onError}>
          <TopNav />
          <div className="py-4">{children}</div>
          <div className="footer-bottom"></div>
          <p className="paragraph-3">
            SocialSwap.io - Copyright 2021 - 2024 © All rights Reserved.
          </p>
          <ScrollRestoration />
          <Scripts />
          <Toaster />
        </WalletProvider>
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}
