import { NavLink, useMatches } from "@remix-run/react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Settings } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside, useWindowSize } from "usehooks-ts";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import WalletConnector from "../web3/wallet_connector";

const TopNav = () => {
  const matches = useMatches();
  const [isTradePage, setIsTradePage] = useState(false);
  const [isLiquidityPage, setIsLiquidityPage] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSocialStakingNavOpen, setIsSocialStakingNavOpen] = useState(false);
  const { width } = useWindowSize();
  const refMobileMenu = useRef(null);

  useEffect(() => {
    setIsTradePage(matches.at(-1)?.pathname === "/trade");
    setIsLiquidityPage(matches.at(-1)?.pathname === "/liquidity");
  }, [matches, setIsTradePage, setIsLiquidityPage]);

  useOnClickOutside(refMobileMenu, () => setMobileMenuOpen(false));

  return (
    <>
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navigation w-nav"
      >
        <div className="grid grid-cols-2 w-full place-items-center justify-between">
          <div className="flex place-items-center justify-self-start">
            <NavLink to="/" className="logo-link w-nav-brand w--current">
              <img
                src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee68e7367ac28ad5c7c42_logo.webp"
                width="104"
                alt="logo: socialswap"
                className="logo-image"
              />
            </NavLink>
            <div className="flex items-center current-price max-h-7 text-green-300 text-xs px-2">
              <span>$0.0010</span> <span className="live-dot"></span>
            </div>
          </div>
          {(width && width >= 1120 && (
            <div className="flex flex-row place-items-center gap-4 justify-self-end">
              <NavLink
                to="/trade"
                className={({ isActive }) =>
                  clsx(
                    "navigation-item w-nav-link",
                    isActive && "!font-bold !opacity-100",
                  )
                }
              >
                socialswap
              </NavLink>
              <DropdownMenu modal={false}>
                <DropdownMenuTrigger className="text-gray-300 flex gap-1">
                  <span>socialstaking</span>
                  <div
                    className="fs-select_arrow-2 w-icon-dropdown-toggle text-gray-400"
                    aria-hidden="true"
                  ></div>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="bg-slate-900 border-0"
                  align="start"
                  alignOffset={-10}
                  side="bottom"
                  onCloseAutoFocus={(e) => e.preventDefault()}
                >
                  <DropdownMenuItem>
                    <NavLink
                      to="/liquidity"
                      className="dropdown-link w-dropdown-link w-full"
                    >
                      Add Liquidity
                    </NavLink>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <a
                      href="pools.html"
                      className="dropdown-link w-dropdown-link w-full"
                    >
                      Pools
                    </a>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <a
                href="http://javascript:void(0)"
                className="navigation-item inactive w-nav-link"
              >
                <span className="op-30">SocialEnergy </span>
                <span className="soon">soon!</span>
              </a>
              {(!isTradePage && !isLiquidityPage && (
                <NavLink
                  to="/trade"
                  className="btn btn-primary stretched w-inline-block"
                >
                  <div className="link">Launch dApp</div>
                </NavLink>
              )) || <WalletConnector />}
              <DropdownMenu modal={false}>
                <DropdownMenuTrigger asChild>
                  <Settings color="#aaa" />
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="bg-slate-900 border-0"
                  align="start"
                  alignOffset={-10}
                  side="bottom"
                >
                  <DropdownMenuItem>
                    <a
                      href="#"
                      className="dropdown-settings navigation-item w-inline-block"
                    >
                      <div className="dropdown-settings">
                        <div className="text-white _w-auto settings-text">
                          Referal Link
                        </div>
                        <div className="icon-xs in-flex w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            aria-hidden="true"
                            role="img"
                            className="iconify iconify--heroicons"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div className="w-layout-hflex settings-info">
                        <div className="icon-embed-xxsmall-3 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            aria-hidden="true"
                            role="img"
                            className="iconify iconify--heroicons"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m11.25 11.25l.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0a9 9 0 0 1 18 0m-9-3.75h.008v.008H12z"
                            ></path>
                          </svg>
                        </div>
                        <div className="settings-info-text">Click to copy</div>
                      </div>
                    </a>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <a
                      href="#"
                      className="dropdown-settings navigation-item w-inline-block"
                    >
                      <div className="dropdown-settings">
                        <div className="text-white _w-auto settings-text">
                          Partners
                        </div>
                        <div className="text-white _w-auto settings-text text-bold">
                          10
                        </div>
                      </div>
                    </a>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )) || (
            <div className="flex justify-self-end cursor-pointer">
              <img
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                src="https://cdn.prod.website-files.com/635397204d0d410851921320/635397204d0d416cdd921331_menu-icon.png"
                width="22"
                alt=""
                className="menu-icon"
              />
            </div>
          )}
        </div>
      </div>
      {width && width < 1120 && (
        <motion.div
          ref={refMobileMenu}
          className="flex w-full bg-[#22232A] text-white place-items-center absolute z-50 rounded-b-lg"
          animate={{
            height: mobileMenuOpen ? "auto" : 0,
            opacity: mobileMenuOpen ? 1 : 0,
          }}
          initial={{
            height: mobileMenuOpen ? 0 : "auto",
            opacity: mobileMenuOpen ? 0 : 1,
          }}
          exit={{ height: 0 }}
          transition={{ ease: "linear", duration: 0.25 }}
        >
          <ul className="list-none justify-center text-center w-full place-items-center pl-0">
            <li>
              <NavLink
                to="/trade"
                className={({ isActive }) =>
                  clsx(
                    "navigation-item w-nav-link",
                    isActive && "!font-bold !opacity-100",
                  )
                }
              >
                socialswap
              </NavLink>
            </li>
            <li>
              <Collapsible
                open={isSocialStakingNavOpen}
                onOpenChange={setIsSocialStakingNavOpen}
              >
                <CollapsibleTrigger asChild>
                  <NavLink
                    to={"#"}
                    className={clsx(
                      "navigation-item w-nav-link relative",
                      isSocialStakingNavOpen && "!font-bold !opacity-100",
                    )}
                  >
                    socialstaking
                    <motion.div
                      className="fs-select_arrow-2 w-icon-dropdown-toggle absolute"
                      aria-hidden="true"
                      animate={{ rotate: isSocialStakingNavOpen ? 180 : 0 }}
                      transition={{ ease: "linear", duration: 0 }}
                    />
                  </NavLink>
                </CollapsibleTrigger>
                <CollapsibleContent className="grid w-auto place-items-center">
                  <NavLink
                    to="/liquidity"
                    className="text-gray-400 hover:text-white w-dropdown-link"
                  >
                    Add Liquidity
                  </NavLink>
                  <a
                    href="pools.html"
                    className="text-gray-400 hover:text-white w-dropdown-link"
                  >
                    Pools
                  </a>
                </CollapsibleContent>
              </Collapsible>
            </li>
            <li>
              <a
                href="http://javascript:void(0)"
                className="navigation-item inactive w-nav-link"
              >
                <span className="op-30">SocialEnergy </span>
                <span className="soon">soon!</span>
              </a>
            </li>
            <li className="justify-center grid w-full">
              {(!isTradePage && !isLiquidityPage && (
                <NavLink
                  to="/trade"
                  className="btn btn-primary stretched w-inline-block w-[350px]"
                >
                  <div className="link">Launch dApp</div>
                </NavLink>
              )) || <WalletConnector className="w-[350px] h-[40px]" />}
            </li>
          </ul>
        </motion.div>
      )}
    </>
  );
};

export default TopNav;
