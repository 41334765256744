import "@tronweb3/tronwallet-adapter-react-ui/style.css";
import {
  WalletActionButton,
  WalletModalProvider,
} from "@tronweb3/tronwallet-adapter-react-ui";
import clsx from "clsx";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import findKey from "lodash/findKey";
import { useCallback, useEffect } from "react";

import useWeb3Store from "~/state/web3_store";
import { Chain } from "~/utils/web3/network";

const WalletConnector = ({ className }: { className?: string }) => {
  const setNetwork = useWeb3Store((state) => state.setNetwork);
  const { wallet, address, signTransaction, connect } = useWallet();
  const refreshNetwork = useCallback(({ chainId }: any) => {
    const networkKey = findKey(Chain, (value) => value === chainId);
    const network = networkKey
      ? Chain[networkKey as keyof typeof Chain]
      : Chain.LOCAL;

    setNetwork(network);
  }, []);

  useEffect(() => {
    wallet?.adapter
      /* @ts-ignore */
      .network()
      .then(refreshNetwork);
    wallet?.adapter.on("chainChanged", refreshNetwork);

    return () => {
      wallet?.adapter.removeAllListeners();
    };
  }, [wallet]);

  return (
    <WalletModalProvider>
      <WalletActionButton
        className={clsx("btn btn-primary stretched w-inline-block", className)}
      ></WalletActionButton>
    </WalletModalProvider>
  );
};

export default WalletConnector;
